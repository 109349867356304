import React 			from "react"
import Layout		  from "../components/layout"
import Para   	  from "../components/paragraph"

const Gallerie = () => (
	<Layout head_gif = "gallerie_bhb.png">
		  <Para txt="Hooksiel, Strand"						  my_img="hooksiel.jpg" my_txt=""/>
			<Para txt="Strand" 												my_img="strand_drachen.jpg" my_txt=""/>
			<Para txt="Lagerfeuer"  									my_img="lagerfeuer.jpg" my_txt=""/>
			<Para txt="Schattiges Plätzchen"					my_img="schattenplaetze.jpg" my_txt=""/>
			<Para txt="Garten, Laube" 		  					my_img="laube.jpg" my_txt=""/>
			<Para txt="Garten"   											my_img="garten.jpg" my_txt=""/>
			<Para txt="Garten, Brunnen"     					my_img="brunnen.jpg" my_txt=""/>
			<Para txt="Garten, nehmen Sie bitte Platz"my_img="platz-zum-sitzen.jpg" my_txt=""/>
			<Para txt="Garten, Blütenzauber"   				my_img="bluetentraum.jpg" my_txt=""/>
	</Layout>
);
export default Gallerie;
